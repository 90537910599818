/* eslint-disable react-hooks/rules-of-hooks */
/*istanbul ignore file*/
import React from "react";
import { FlexRow, FlexColumn } from "../../../../../view/shared/base/FlexBox";
import BoxImage from "../../../../../view/shared/base/Image/BoxImage";
import Text from "../../../../../view/shared/base/Text";
import useTimerCalculation from "../../../../../hooks/useTimerCalculation";
import styles from "./styles.module.scss";
import bgDesktop from "../../../../../assets/images/bg_horizontal.jpg";
import bgMobile from "../../../../../assets/images/bg_vertical.jpg";
import useLayout from "../../../../../hooks/useLayout";
import { useSpring, animated } from "@react-spring/web";
import Box from "@mui/material/Box";

const DeadLineCounter = ({ show }) => {
  if (!show) return null;
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const deadlineDate = 1740182400000;
  const { isDesktop } = useLayout();

  const { days, hours, minutes, seconds } = useTimerCalculation({
    endDate: deadlineDate,
  });

  const textFontSize = { xs: 12, xl: 16 };

  const getClockBlock = (numbers, text) => {
    return (
      <FlexColumn relative key={`${numbers}-${text}`}>
        <FlexColumn
          m={{ xs: 0.5, md: 1 }}
          jcCenter
          aiCenter
          className={styles.square}
          gap={3}
          width={{ xs: 25, sm: 30, md: 50, xl: 90 }}
          height={{ xs: 25, sm: 30, md: 50, xl: 90 }}
          p={4}
          key={numbers}
        >
          <Text xs_h4 md_h2 colorInherit>
            {numbers.toString().padStart(2, "0")}
          </Text>
        </FlexColumn>
        <Text
          py={1}
          colorInherit
          bold
          nowrap
          sx={{
            fontSize: textFontSize,
          }}
          colorTextGrey
          alignCenter
        >
          {text}
        </Text>
      </FlexColumn>
    );
  };

  const cssDesktop = {
    position: "absolute",
    left: "50%",
    top: 30,
    transform: "translateX(-50%)",
  };
  const cssMobile = {
    position: "absolute",
    top: 30,
    left: "50%",
    transform: "translateX(-50%)",
  };

  return (
    <Box className={styles.root}>
      <FlexRow relative fullWidth className={styles.page}>
        <BoxImage
          mobile={isDesktop ? bgDesktop : bgMobile}
          desktop={isDesktop ? bgDesktop : bgMobile}
          sx={{ width: "100%" }}
        />
        <animated.div style={props}>
          <FlexColumn
            gap={3}
            sx={isDesktop ? { ...cssDesktop } : { ...cssMobile }}
          >
            <FlexRow jcCenter aiCenter>
              {getClockBlock(days, "DÍAS")}

              {getClockBlock(hours, "HORAS")}

              {getClockBlock(minutes, "MIN")}

              {getClockBlock(seconds, "SEG")}
            </FlexRow>
          </FlexColumn>
        </animated.div>
      </FlexRow>
    </Box>
  );
};

DeadLineCounter.propTypes = {};

export default DeadLineCounter;
