import React, { useEffect } from "react";
import useErrorBoundary from "use-error-boundary";
import { useRoutes } from "react-router-dom";
import Text from "../../../view/shared/base/Text/index.js";
import PageHome from "../PageHome";
import PageDashboard from "../PageDashboard";
import { ThemeProvider } from "@mui/material/styles";
import muiTheme from "../../../config/muiTheme";
//import Snackbar from "../../../view/shared/base/Snackbar/Loadable";
//import Header from "../../shared/components/Header/index.js";
import Footer from "../../shared/components/Footer/index.js";
import { ReduxSlice } from "../../../config/reduxSlice";
import saga from "../../../redux/global/saga";
import { useInjectSaga } from "../../../core/redux/injectSaga";
import { detectScreen, setAppMounted } from "../../../redux/global/actions";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import styles from "./styles.module.scss";

const Home = () => {
  return <PageHome />;
};

const Dashboard = () => {
  return <PageDashboard />;
};

const AppRoutes = () => {
  const routes = useRoutes([
    { path: "/meli&nes", element: <Home /> },
    { path: "/meli&nes/:id", element: <Home /> },
    { path: "/meli&nes/dashboard", element: <Dashboard /> },
    { path: "/", element: <Navigate to="/meli&nes" replace /> },
  ]);

  return routes;
};

const App = () => {
  useInjectSaga({ key: ReduxSlice.global, saga });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAppMounted());
    dispatch(detectScreen());

    const setDeviceType = debounce(() => dispatch(detectScreen()), 250);
    const handleResize = () => setDeviceType();

    // detect screen when resizing as well
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { ErrorBoundary } = useErrorBoundary();

  return (
    <ErrorBoundary
      render={() => (
        <ThemeProvider theme={muiTheme}>
          <div className={styles.app} data-testid="app-container">
            <div className={styles.content}>
              {/*snackBarOpen && <Snackbar />*/}
              {/* <Header /> */}
              <Router>
                <AppRoutes />
              </Router>
              {/* <Footer /> */}
            </div>
          </div>
        </ThemeProvider>
      )}
      renderError={(error) => <Text colorError>{error}</Text>}
      data-testid="error-boundary"
    />
  );
};
export default App;
