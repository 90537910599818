import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

let cx = classNames.bind(styles);

const PageContainer = ({ padding, fullHeight, hide, children, className, sx, ...props }) => {
    if (hide) return null;

    return (
        <Box
            sx={{ height: fullHeight ? "100%" : "auto", width: "100%", ...sx }}
            className={cx(styles.container, className, { [styles.containerPadding]: padding })}
            {...props}>
            {children}
        </Box>
    );
};

PageContainer.propTypes = {
    hide: PropTypes.bool,
    padding: PropTypes.bool,
    fullHeight: PropTypes.bool
};

export default PageContainer;
