/*istanbul ignore file*/
import { useState, useMemo } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
const useInvitado = () => {
  const { id } = useParams();
  const [invitado, setInvitado] = useState({});
  const [allInvitados, setAllInvitados] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const getInvitado = async () => {
    setLoading(true);
    fetch(`https://invita.agavesentreotros.com/invitados/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setInvitado(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };
  const getAllInvitados = async () => {
    setLoading(true);
    fetch(`https://invita.agavesentreotros.com/invitados`)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setAllInvitados(data);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  const confirmaAsistencia = async ({ comments, confirm, pases_usados }) => {
    setLoading(true);
    const data = {
      id_hash: id,
      comments,
      confirm,
      pases_usados,
    };

    try {
      await axios.put("https://invita.agavesentreotros.com/invitados", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      getInvitado();
      setLoading(false);
      return {
        error: false,
        message: "Se ha enviado la información, gracias!",
      };
    } catch (error) {
      setLoading(false);
      return { error: true, message: "Ocurrió un error, intenta más tarde." };
    }
  };

  const totalPases = useMemo(() => {
    let total = 0;
    allInvitados.forEach((invitado) => {
      total += parseInt(invitado.pases, 10) || 0;
    });
    return total;
  }, [allInvitados]);

  const totalPasesUsados = useMemo(() => {
    let total = 0;
    allInvitados.forEach((invitado) => {
      total += parseInt(invitado.pases_usados, 10) || 0;
    });
    return total;
  }, [allInvitados]);

  const totalRespondido = useMemo(() => {
    return allInvitados.filter((invitado) => invitado.confirma === "1").length;
  }, [allInvitados]);

  return {
    loading,
    //
    allInvitados,
    totalPases,
    totalPasesUsados,
    totalRespondido,
    invitado,
    error,
    //
    getInvitado,
    getAllInvitados,
    confirmaAsistencia,
  };
};

export default useInvitado;
