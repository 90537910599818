import React, { useMemo } from "react";
import ImageGallery from "react-image-gallery";
import MainTitle from "../../../../shared/base/MainTitle";
import useLayout from "../../../../../hooks/useLayout";
import { FlexColumn } from "../../../../shared/base/FlexBox";
import Divider from "../../../../shared/base/Divider";
import "react-image-gallery/styles/css/image-gallery.css";

const DeadLineCounter = () => {
  const { isDesktop } = useLayout();
  const label = isDesktop ? "horizontal" : "vertical";
  const imgs = useMemo(() => {
    let imgs = [];
    for (let i = 1; i < 33; i++) {
      try {
        const image = require(`../../../../../assets/images/gallery/${label}/sin título-${i}.jpg`);
        imgs.push({ original: image, thumbnail: image });
      } catch (error) {}
    }
    return imgs;
  }, [label]);

  return (
    <FlexColumn jcCenter aiCenter pt={6}>
      <MainTitle title="¡Nuestros momentos felices!" />
      <ImageGallery showThumbnails={false} items={imgs} />
      <Divider />
    </FlexColumn>
  );
};

export default DeadLineCounter;
