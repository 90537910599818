/* eslint-disable react-hooks/rules-of-hooks */
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TextConfig from "./config";
import Box from "@mui/material/Box";
import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

const responsiveTypoMap = (() => {
  let map = {};
  for (let i in TextConfig.breakpoints) {
    const br = TextConfig.breakpoints[i];
    for (let typo in TextConfig.variantsProps) {
      map[`${br}_${typo}`] = {
        variant: typo,
        breakpoint: br,
        breakPointIndex: i,
      };
    }
  }
  return map;
})();

const fontWeightMap = (() => {
  let map = {};
  for (let w in TextConfig.fontWeights) {
    map[TextConfig.fontWeights[w]] = true;
  }
  return map;
})();

const Text = ({ className, children, style, ...otherProps }) => {
  const theme = useTheme();
  const media = {};
  const { pending, pendingProps, component, ...props } = otherProps;
  const { onClick } = props;

  useEffect(() => {
    onClick?.();
  }, []);

  TextConfig.breakpoints.forEach((br) => {
    media[br] = useMediaQuery(theme.breakpoints.up(br));
  });

  let other = {};
  let typoProps = { ...TextConfig.defaults };

  if (component) {
    typoProps = { ...typoProps, component };
  }

  let responsiveTypoProps = [];
  let css = [];
  let allStyle = { ...style };
  for (let key in props) {
    const value = props[key];

    if (value === undefined) continue;

    if (responsiveTypoMap[key]) {
      if (value) responsiveTypoProps.push(responsiveTypoMap[key]);
    } else if (fontWeightMap[key]) {
      if (value) css.push(styles[key]);
    } else if (TextConfig.variantsProps[key]) {
      if (value) typoProps.variant = TextConfig.variantsProps[key];
    } else if (TextConfig.colorsProps[key]) {
      if (value) typoProps.color = TextConfig.colorsProps[key];
    } else if (TextConfig.decorationsProps[key]) {
      if (value) css.push(TextConfig.decorationsProps[key]);
    } else if (TextConfig.css[key]) {
      if (value) css.push(TextConfig.css[key]);
    } else if (TextConfig.style[key]) {
      allStyle = {
        ...allStyle,
        ...TextConfig.style[key](value),
      };
    } else if (TextConfig.other[key]) {
      other = {
        ...other,
        ...TextConfig.other[key](value),
      };
    } else {
      other[key] = value;
    }
  }

  responsiveTypoProps = responsiveTypoProps
    .filter((x) => media[x.breakpoint])
    .sort((a, b) => b.breakPointIndex - a.breakPointIndex);
  typoProps.variant = responsiveTypoProps[0]?.variant ?? typoProps.variant;

  if (pending) {
    const { className: pendingCss, ...others } = pendingProps || {};

    return (
      <Typography
        {...typoProps}
        {...other}
        style={allStyle}
        className={classNames(css, className)}
      >
        <Box className={cx(styles.pending, pendingCss)} {...others}>
          &nbsp;
        </Box>
      </Typography>
    );
  }
  return (
    <Typography
      {...typoProps}
      {...other}
      style={allStyle}
      className={classNames(css, className)}
    >
      {children}
    </Typography>
  );
};

export default Text;

Text.propTypes = {
  //Functions
  onClick: PropTypes.func,
  // pending
  pending: PropTypes.bool,
  pendingProps: PropTypes.object,
  //standard
  className: PropTypes.string,
  component: PropTypes.string,
  variant: PropTypes.string,
  // common
  sx: PropTypes.object,
  top: PropTypes.number,
  block: PropTypes.bool,
  fullWidth: PropTypes.bool,
  pointer: PropTypes.bool,
  noUserSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  hidden: PropTypes.bool,
  ellipsis: PropTypes.bool,
  relative: PropTypes.bool,
  // variants
  h1: PropTypes.bool,
  h2: PropTypes.bool,
  h3: PropTypes.bool,
  h4: PropTypes.bool,
  h5: PropTypes.bool,
  h6: PropTypes.bool,
  body1: PropTypes.bool,
  body2: PropTypes.bool,
  subtitle1: PropTypes.bool,
  subtitle2: PropTypes.bool,
  caption: PropTypes.bool,
  inherit: PropTypes.bool,
  fontInherit: PropTypes.bool,
  fontSizeInherit: PropTypes.bool,
  fontWeightInherit: PropTypes.bool,
  lineHeightInherit: PropTypes.bool,
  // colors
  colorError: PropTypes.bool,
  colorLightGray: PropTypes.bool,
  colorRed: PropTypes.bool,
  colorWhite: PropTypes.bool,
  colorInherit: PropTypes.bool,
  colorPrimary: PropTypes.bool,
  colorSecondary: PropTypes.bool,
  colorTextPrimary: PropTypes.bool,
  colorTextSecondary: PropTypes.bool,
  colorTextWhite: PropTypes.bool,
  colorTextGrey: PropTypes.bool,
  colorTextGreyStrong: PropTypes.bool,
  colorTextYellow: PropTypes.bool,
  colorTextLink: PropTypes.bool,
  colorPrimaryLink: PropTypes.bool,
  color: PropTypes.string,
  // font weight
  light: PropTypes.bool,
  regular: PropTypes.bool,
  medium: PropTypes.bool,
  bold: PropTypes.bool,
  bolder: PropTypes.bool,
  // decorations
  italic: PropTypes.bool,
  nowrap: PropTypes.bool,
  underline: PropTypes.bool,
  fontWeight: PropTypes.number,
  alignLeft: PropTypes.bool,
  alignCenter: PropTypes.bool,
  alignJustify: PropTypes.bool,
  alignRight: PropTypes.bool,
  uppercase: PropTypes.bool,
  capitalize: PropTypes.bool,
  textDecorationNone: PropTypes.bool,
  textTransformNone: PropTypes.bool,
  letterSpacing: PropTypes.number,
  lineThrough: PropTypes.bool,
  // other
  span: PropTypes.bool,
  div: PropTypes.bool,
  lineHeight: PropTypes.number,
  // responsive props
  // xs
  xs_h1: PropTypes.bool,
  xs_h2: PropTypes.bool,
  xs_h3: PropTypes.bool,
  xs_h4: PropTypes.bool,
  xs_h5: PropTypes.bool,
  xs_h6: PropTypes.bool,
  xs_caption: PropTypes.bool,
  xs_body1: PropTypes.bool,
  xs_body2: PropTypes.bool,
  xs_subtitle1: PropTypes.bool,
  xs_subtitle2: PropTypes.bool,
  // sm
  sm_h1: PropTypes.bool,
  sm_h2: PropTypes.bool,
  sm_h3: PropTypes.bool,
  sm_h4: PropTypes.bool,
  sm_h5: PropTypes.bool,
  sm_h6: PropTypes.bool,
  sm_caption: PropTypes.bool,
  sm_body1: PropTypes.bool,
  sm_body2: PropTypes.bool,
  sm_subtitle1: PropTypes.bool,
  sm_subtitle2: PropTypes.bool,
  // md
  md_h1: PropTypes.bool,
  md_h2: PropTypes.bool,
  md_h3: PropTypes.bool,
  md_h4: PropTypes.bool,
  md_h5: PropTypes.bool,
  md_h6: PropTypes.bool,
  md_caption: PropTypes.bool,
  md_body1: PropTypes.bool,
  md_body2: PropTypes.bool,
  md_subtitle1: PropTypes.bool,
  md_subtitle2: PropTypes.bool,
  // lg
  lg_h1: PropTypes.bool,
  lg_h2: PropTypes.bool,
  lg_h3: PropTypes.bool,
  lg_h4: PropTypes.bool,
  lg_h5: PropTypes.bool,
  lg_h6: PropTypes.bool,
  lg_caption: PropTypes.bool,
  lg_body1: PropTypes.bool,
  lg_body2: PropTypes.bool,
  lg_subtitle1: PropTypes.bool,
  lg_subtitle2: PropTypes.bool,
  // lx
  xl_h1: PropTypes.bool,
  xl_h2: PropTypes.bool,
  xl_h3: PropTypes.bool,
  xl_h4: PropTypes.bool,
  xl_h5: PropTypes.bool,
  xl_h6: PropTypes.bool,
  xl_caption: PropTypes.bool,
  xl_body1: PropTypes.bool,
  xl_body2: PropTypes.bool,
  xl_subtitle1: PropTypes.bool,
  xl_subtitle2: PropTypes.bool,
};
