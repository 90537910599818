// must be used inside the body of a function component for the hook (useSelector) to work
export const getImgSrc = (imgDirectory, { nextVersion = false, useSmall = false, smallHeight = 24, width = null, height = null } = {}) => {
    const hasHost = /^\s*https?:\/\//.test(imgDirectory);
    if (hasHost) return imgDirectory;

    const path = (imgDirectory || "").replace(/^\//, "");
    const host = process.env.LOYALTY_WEB_AKAMAI;
    const calcHeight = useSmall ? smallHeight : height;
    const params = [
        width ? `im=Resize,width=${width}` : null,
        calcHeight ? `im=Resize,height=${calcHeight}` : null,
        nextVersion ? "v=1" : null
    ]
        .filter(x => !!x)
        .join("&");

    return `${host}${path}?${params}`;
};

export const getSizedImgSrc = (imgDirectory, { width, height, size } = {}) => {
    return `${process.env.LOYALTY_WEB_AKAMAI}${imgDirectory}?im=Resize,width=${width || size},height=${height || size}`;
};

export const getImgFromBlob = blobArray => {
    const blob = new Blob([blobArray]);
    /*istanbul ignore next: won't happen in jest env*/
    return URL.createObjectURL && URL.createObjectURL(blob);
};

// set image url width to the original width * reducePercent if original width exists, or 25 otherwise.
export const getTinyImgUrl = (imgixUrl, reducePercent = 5) => {
    const [urlOriginal, widthOriginal] = imgixUrl.split("width=");
    const width = widthOriginal ? (parseInt(widthOriginal, 10) * reducePercent) / 100 : 25;
    return `${urlOriginal}&width=${width}`;
};

export const assetToImgUrls = ({ x1, x2, x3 } = {}) => {
    return {
        x1: getImgSrc(x1),
        x2: getImgSrc(x2),
        x3: getImgSrc(x3)
    };
};

export const bgImageStyle = url => ({ backgroundImage: `url("${url}")` });
