import React from "react";
import { FlexColumn } from "../../../../shared/base/FlexBox";
import Text from "../../../../shared/base/Text";

const Blessed = ({ invitado = {} }) => {
  const { invitado_desc, pases } = invitado;
  if (!invitado_desc) {
    return null;
  }
  return (
    <FlexColumn
      fullWidth
      py={{ xs: 10, md: 20 }}
      sx={{ backgroundColor: "#AEA69D" }}
    >
      {invitado_desc && (
        <>
          {" "}
          <Text
            pb={7}
            xs_h3
            md_h2
            lineHeight={1.5}
            colorTextWhite
            alignCenter
            wrap
          >
            Estimado(s):
            <Text xs_h3 md_h2 colorTextRed alignCenter wrap>
              <br /> {invitado_desc}
            </Text>
          </Text>{" "}
        </>
      )}
      <Text
        px={10}
        xs_h4
        md_h2
        alignCenter
        letterSpacing={1}
        lineHeight={1.5}
        colorTextWhite
        wrap
      >
        Con la bendición de Dios y el apoyo de nuestros padres,
        <br /> <br />
        Baltazar Solano
        <br />
        Luz María Gamiño
        <br />
        y
        <br />
        María del Carmen Herrera
        <br />
        Francisco Javier García,
        <br /> <br />
        deseamos celebrar nuestro matrimonio y esperamos contar con tu
        presencia.
      </Text>
    </FlexColumn>
  );
};
export default Blessed;
