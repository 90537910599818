import React from "react";
import { FlexColumn } from "../../../../shared/base/FlexBox";
import Text from "../../../../shared/base/Text";
import BoxImage from "../../../../../view/shared/base/Image/BoxImage";
import hotelFoto from "../../../../../../src/assets/images/hotel_foto.jpg";

const Hotel = () => {
  return (
    <FlexColumn
      aiCenter
      jcCenter
      fullWidth
      py={15}
      sx={{ backgroundColor: "#AEA69D" }}
    >
      <Text uppercase xs_h3 md_h1 letterSpacing={1.5}>
        Opción de Hospedaje
      </Text>
      <BoxImage
        py={5}
        mobile={hotelFoto}
        desktop={hotelFoto}
        sx={{
          width: "90%",
          minWidth: 45,
          maxWidth: 780,
          borderRadius: "25x",
        }}
      />

      <Text xs_caption md_h4 letterSpacing={1.5} colorTextGreyStrong>
        Hotel 5 Inn Pluss
      </Text>
      <Text
        py={5}
        px={10}
        xs_caption
        md_h4
        alignCenter
        letterSpacing={1}
        lineHeight={1}
        colorTextGreyStrong
        wrap
      >
        Habitación doble para 2 personas $990
      </Text>
      <Text
        pb={5}
        px={10}
        xs_caption
        md_h4
        alignCenter
        letterSpacing={1}
        lineHeight={1}
        colorTextGreyStrong
        wrap
      >
        Tel: 477 700 36 33 con Alejandra Quiroz
      </Text>
      <Text
        mx={5}
        p={5}
        xs_caption
        md_h4
        alignCenter
        letterSpacing={1}
        lineHeight={1}
        wrap
        sx={{ backgroundColor: "white", borderRadius: "20px" }}
      >
        Código : Imelda y Néstor 2202
      </Text>
    </FlexColumn>
  );
};
export default Hotel;
