import React from "react";

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <p style={styles.text}>
          © {new Date().getFullYear()} Sogacode. Todos los derechos reservados.
        </p>
        {/*<div style={styles.links}>
          <a href="/privacy" style={styles.link}>
            Política de Privacidad
          </a>
          <span style={styles.separator}>|</span>
          <a href="/terms" style={styles.link}>
            Términos y Condiciones
          </a>
        </div>*/}
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: "#D9CFC3", // Gris claro que combina con #F0E6D6
    padding: "20px 0",
    textAlign: "center",
    marginTop: "auto",
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "0 20px",
  },
  text: {
    color: "#4F4F4F", // Gris oscuro para contraste
    margin: "0",
    fontSize: "14px",
  },
  links: {
    marginTop: "10px",
  },
  link: {
    color: "#6B6B6B", // Gris intermedio
    textDecoration: "none",
    fontSize: "14px",
    margin: "0 5px",
  },
  separator: {
    color: "#4F4F4F",
    margin: "0 10px",
  },
};

export default Footer;
