import React, { useState } from "react";
import { FlexColumn, FlexRow } from "../../../../shared/base/FlexBox";
import Text from "../../../../shared/base/Text";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import MainTitle from "../../../../shared/base/MainTitle";

const Parents = () => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const array = [
    {
      name: "Velación",
      value: "Luz Vargas",
      value2: "Ricardo Pablo",
    },
    {
      name: "Anillos",
      value: "Nathalie Solano",
      value2: "Francisco  Solano",
    },
    {
      name: "Arras",
      value: "Cristina Magaña",
      value2: "Francisco  García",
    },
    {
      name: "Lazo",
      value: "Claudia Sánchez",
      value2: "Marco Pablo",
    },
    {
      name: "Biblia",
      value: "Lupita Aranda",
      value2: "Gabriel Juárez",
    },

    {
      name: "Cristo",
      value: "Itzel Barajas",
      value2: "Omar Solano",
    },
  ];

  return (
    <FlexColumn fullWidth aiCenter jcCenter>
      <MainTitle
        title="Nuestros Padrinos"
        onClick={handleClick}
        sx={{ marginBottom: 0 }}
      />
      <Accordion
        expanded={expanded}
        onChange={handleClick}
        style={{
          width: "100%",
          backgroundColor: "transparent",
          border: "none",
          mx: "20px",
          p: 0,
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        ></AccordionSummary>
        <AccordionDetails
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            border: "none",
          }}
        >
          {array.map((item, index) => (
            <FlexRow fullWidth py={8} key={index} gap={10} aiCenter>
              <FlexColumn
                aiEnd
                gap={3}
                sx={{ flexBasis: { xs: "40%", md: "50%" } }}
              >
                <Text h4 bold colorTextSecondary>
                  {item.name}
                </Text>
              </FlexColumn>
              <FlexColumn sx={{ flexBasis: { xs: "60%", md: "50%" } }} gap={3}>
                <Text h4 bold wrap>
                  {item.value}
                </Text>
                <Text h4 bold wrap colorTextSecondary>
                  &
                </Text>
                <Text h4 bold wrap>
                  {item.value2}
                </Text>
              </FlexColumn>
            </FlexRow>
          ))}
        </AccordionDetails>
      </Accordion>
    </FlexColumn>
  );
};

export default Parents;
