/* eslint-disable react-hooks/rules-of-hooks */
/*istanbul ignore file*/
import React from "react";
import { FlexColumn, FlexBox } from "../../../../../view/shared/base/FlexBox";
import BoxImage from "../../../../../view/shared/base/Image/BoxImage";
import MainTitle from "../../../../shared/base/MainTitle";
import Text from "../../../../../view/shared/base/Text";
import catedral from "../../../../../assets/images/catedral.jpg";
import cassia from "../../../../../assets/images/Cassia.jpeg";
import useLayout from "../../../../../hooks/useLayout";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PapperCardBase from "../../../../shared/base/PapperCardBase";

const WhenAndWhere = () => {
  const { isDesktop } = useLayout();

  const Card = ({
    image,
    title,
    subtitle,
    schedule,
    address = "",
    href = "",
  }) => {
    return (
      <PapperCardBase
        sx={{ paddingTop: 0, maxWidth: { xs: "90%", md: 300, lg: 500 } }}
      >
        <BoxImage
          mobile={image}
          desktop={image}
          sx={{
            width: "100%",
            borderRadius: "10px",
            //aspectRatio: "1.5/1",
            filter: "grayscale(100%)",
          }}
        />
        <FlexColumn gap={3} p={5} fullWidth jcCenter aiCenter>
          <Text xs_h2 md_h3 lg_h2 bold alignCenter colorTextRed wrap>
            {title}
          </Text>
          <Text caption alignCenter>
            {subtitle}
          </Text>
          <Text caption alignCenter colorTextRed>
            {schedule}
          </Text>

          <LocationOnIcon />
          <Text caption alignCenter wrap colorTextSecondary italic>
            <Link
              to={href}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {address}
            </Link>
          </Text>
        </FlexColumn>
      </PapperCardBase>
    );
  };

  return (
    <FlexColumn fullWidth jcCenter aiCenter>
      <MainTitle title="¿Cuándo y dónde?" hideDivider />

      <FlexBox
        gap={10}
        isRow={isDesktop}
        fullHeight
        fullWidth
        jcCenter
        aiCenter
      >
        {Card({
          image: catedral,
          title: "Ceremonia Religiosa",
          subtitle: "Catedral León ",
          schedule: "5:00 P.M.",
          address: "Ir a ubicación",
          href: "https://maps.app.goo.gl/j4iXvbjHwnB8sShC7?g_st=iw",
        })}
        {Card({
          image: cassia,
          title: "Fiesta",
          subtitle: "Hacienda La Cassia ",
          schedule: "7:00 P.M.",
          address: "Ir a ubicación",
          href: "https://maps.google.com?q=Hacienda%20la%20cassia,%20Blvd.%20Aeropuerto%202401,%20Fracciones%20de%20los%20Aguirre,%2037680%20Le%C3%B3n%20de%20los%20Aldama,%20Gto.&ftid=0x842b971fcf85c59d:0xafa1d494bf6f7025&hl=es-MX&gl=mx&entry=gps&lucs=,94242595,47071704,47069508,47084304&g_st=iw",
        })}
      </FlexBox>
    </FlexColumn>
  );
};

export default WhenAndWhere;
