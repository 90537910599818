/* eslint-disable react-hooks/rules-of-hooks */
/*istanbul ignore file*/
import React from "react";
import PropTypes from "prop-types";
import Divider from "../../../../view/shared/base/Divider";
import Text from "../../../../view/shared/base/Text";
import { FlexRow } from "../FlexBox";

const MainTitle = ({
  title,
  hideDivider = false,
  fullWidth = false,
  onClick = () => {},
  sx = {},
}) => {
  return (
    <>
      {hideDivider ? null : <Divider />}
      <FlexRow
        mt={5}
        mb={10}
        jcCenter
        aiCenter
        sx={{
          width: fullWidth ? "100%" : "90%",
          backgroundColor: "#a62a2a",
          borderRadius: "10px",
          ...sx,
        }}
      >
        <Text
          xs_h3
          md_h1
          py={5}
          bold
          alignCenter
          colorTextWhite
          onClick={onClick}
        >
          {title}
        </Text>
      </FlexRow>
    </>
  );
};

MainTitle.propTypes = {
  title: PropTypes.string,
};

export default MainTitle;
