import React from "react";
import { FlexColumn } from "../../../../shared/base/FlexBox";
import Text from "../../../../shared/base/Text";
import BoxImage from "../../../../../view/shared/base/Image/BoxImage";
import logo_general from "../../../../../assets/images/logo_general.png";
import PapperCardBase from "../../../../shared/base/PapperCardBase";
import { Link } from "react-router-dom";
import mesaLiv from "../../../../../assets/images/mesa-liv.png";
import AdsClickIcon from "@mui/icons-material/AdsClick";

const Cards = ({ invitado = {} }) => {
  const { invitado_desc, pases } = invitado;
  if (!invitado_desc) {
    return null;
  }
  return (
    <FlexColumn jcStart aiCenter relative>
      <PapperCardBase
        sx={{
          position: "relative",
          left: { xs: "-50px", md: "-100px" },
          maxWidth: { xs: 250, md: 400 },
          height: { xs: 250, md: 500, lg: 600 },
          paddingTop: { xs: 5, md: 10 },
          paddingBottom: { xs: 10, md: 10 },
        }}
      >
        <BoxImage
          mobile={logo_general}
          desktop={logo_general}
          sx={{
            width: "30%",
          }}
        />
        <Text
          xs_h6
          lg_h2
          letterSpacing={1.5}
          uppercase
          alignCenter
          colorTextRed
        >
          RECEPCIÓN
        </Text>

        <Text py={10} xs_body1 lg_h2 colorTextSecondary alignCenter>
          Hacienda La Cassia
          <br />
          <br />
          7:00 P.M.
          <br />
          <br />({pases}) PERSONAS | NO NIÑOS
        </Text>
      </PapperCardBase>

      <PapperCardBase
        sx={{
          position: "relative",
          right: { xs: "-25px", sm: "50px", md: "-200px" },
          top: { xs: "-10px", md: "-50px" },
          maxWidth: { xs: 250, md: 400 },
          height: { xs: 250, md: 500, lg: 600 },
          boxShadow: "-10px -10px 10px rgba(0, 0, 0, 0.3)",
        }}
      >
        <BoxImage
          mobile={mesaLiv}
          desktop={mesaLiv}
          sx={{
            width: "30%",
          }}
        />
        <Text
          xs_h6
          lg_h2
          letterSpacing={1.5}
          uppercase
          alignCenter
          colorTextRed
        >
          Mesa de regalos
        </Text>
        <Text pt={10} xs_body1 lg_h2 alignCenter colorTextSecondary>
          Liverpool
        </Text>
        <Text
          p={3}
          xs_h6
          lg_h2
          alignCenter
          colorTextRed
          sx={{ border: "1px solid #9a9391", borderRadius: "10px" }}
        >
          <Link
            to={
              "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51571677"
            }
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            51571677 <AdsClickIcon />
          </Link>
        </Text>
      </PapperCardBase>
    </FlexColumn>
  );
};

export default Cards;
