import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
import { FlexColumn, FlexRow } from "../../../view/shared/base/FlexBox";
import LoadingIndicator from "../../../view/shared/base/LoadingIndicator";
import Text from "../../../view/shared/base/Text";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import useInvitado from "../../../hooks/useInvitado";
import MainTitle from "../../shared/base/MainTitle";

let cx = classNames.bind(styles);

const PageDashboard = () => {
  const {
    loading,
    allInvitados,
    totalPases,
    totalPasesUsados,
    totalRespondido,
    getAllInvitados,
  } = useInvitado();

  useEffect(() => {
    getAllInvitados();
  }, []);

  const percentagePases = Math.round((totalPasesUsados / totalPases) * 100);
  const percentageRespondidos = Math.round(
    (totalRespondido / allInvitados.length) * 100
  );

  const getBackGroundColor = (index, confirma) => {
    if (confirma === "1") {
      return "#85b587";
    }
    if (confirma === "0") {
      return "#d37878";
    }
    if (index % 2 === 0) {
      return "white";
    } else {
      return "#f9f9f9";
    }
  };

  if (loading) return <LoadingIndicator />;

  const ChartData = ({ label, percentage }) => {
    return (
      <FlexColumn
        fullWidth
        aiCenter
        jcCenter
        gap={5}
        m={5}
        p={5}
        sx={{
          minWidth: "200px",
          maxWidth: "300px",
          backgroundColor: "#F9F9F9",
          borderRadius: "10px",
        }}
      >
        <Text h3 colorTextSecondary>
          {label}
        </Text>
        <CircularProgressbar value={percentage} text={`${percentage}%`} />
      </FlexColumn>
    );
  };

  return (
    <Box className={styles.root} m={3}>
      <FlexColumn jcCenter aiCenter className={styles.page}>
        <MainTitle title={"Dashboard"} />
        <FlexRow aiCenter jcCenter wrap>
          <ChartData label={"Pases tomados"} percentage={percentagePases} />
          <ChartData
            label={"Respuestas recibidas"}
            percentage={percentageRespondidos}
          />
        </FlexRow>

        <MainTitle title={"Todos los invitados"} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #ccc",
                    fontWeight: "bold",
                  }}
                >
                  Invitados
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ccc",
                    fontWeight: "bold",
                  }}
                >
                  Confirma
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ccc",
                    fontWeight: "bold",
                  }}
                >
                  Pases Dados
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ccc",
                    fontWeight: "bold",
                  }}
                >
                  Pases Usados
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ccc",
                    fontWeight: "bold",
                  }}
                >
                  Comentarios
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allInvitados.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: getBackGroundColor(index, row.confirma),
                  }}
                >
                  <TableCell sx={{ border: "1px solid #ccc" }}>
                    <Text colorTextSecondary={index % 2 === 0}>
                      {row.invitado_desc}
                    </Text>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ccc" }}>
                    <Text colorTextSecondary={index % 2 === 0}>
                      {row.confirma === "1"
                        ? "Sí"
                        : row.confirma === "0"
                        ? "No"
                        : "Sin respuesta"}
                    </Text>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ccc" }}>
                    <Text colorTextSecondary={index % 2 === 0}>
                      {row.pases}
                    </Text>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ccc" }}>
                    <Text colorTextSecondary={index % 2 === 0}>
                      {row.pases_usados}
                    </Text>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ccc" }}>
                    <Text colorTextSecondary={index % 2 === 0}>
                      {row.comentarios}
                    </Text>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FlexColumn>
    </Box>
  );
};

export default PageDashboard;
